<template>
  <MainLayout>
    <template v-slot:toolbar>
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Audiências</h1>
        <!--end::Title-->
        <div class="mt-3" v-if="localAudiences.length > 0">
          <p class="mb-0">
            <strong>Resultados:</strong> {{ localAudiences.length }}/{{ total }}
          </p>
        </div>
      </div>
      <!--begin::Actions-->
      <div class="d-flex align-items-center gap-2 gap-lg-3 position-relative">
        <!--begin::Filter menu-->
        <div class="m-0">
          <!--begin::Menu toggle-->
          <a
            href="#"
            @click.prevent="isFiltersOpen = !isFiltersOpen"
            class="btn btn-sm btn-flex bg-body btn-color-gray-700 btn-active-color-primary fw-bold"
          >
            <!--begin::Svg Icon | path: icons/duotune/general/gen031.svg-->
            <span class="svg-icon svg-icon-6 svg-icon-muted me-1">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z" fill="currentColor" />
              </svg>
              Filtrar
            </span>
            <!--end::Svg Icon-->
          </a>
          <!--end::Menu toggle-->
          <!--begin::Menu 1-->
          <div
            class="menu menu-sub menu-sub-dropdown w-250px w-md-300px position-absolute top-0 end-0 mt-4"
            :class="{
              'show': isFiltersOpen
            }"
          >
            <!--begin::Header-->
            <div class="px-7 py-5">
              <div class="fs-5 text-dark fw-bold">Opções de Filtro</div>
            </div>
            <!--end::Header-->
            <!--begin::Menu separator-->
            <div class="separator border-gray-200"></div>
            <!--end::Menu separator-->
            <!--begin::Form-->
            <div class="px-7 py-5">
              <!--begin::Input group-->
              <div class="mb-5">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Busca por nome:</label>
                <!--end::Label-->
                <!--begin::Input-->
                <div>
                  <input type="text" class="form-control form-control-solid" placeholder="" v-model.trim="filters.search"/>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="mb-5">
                <!--begin::Label-->
                <label class="form-label fw-semibold">Status</label>
                <!--end::Label-->
                <!--begin::Input-->
                <div class="mb-3">
                  <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                    <input class="form-check-input" type="checkbox" value="" name="only_used" v-model="filters.is_active" />
                    <label class="form-check-label">Apenas audiências ativas</label>
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
              <!--begin::Actions-->
              <div class="d-flex justify-content-end">
                <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true" @click="reset">Resetar</button>
                <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true" @click="applyFilters">Aplicar</button>
              </div>
              <!--end::Actions-->
            </div>
            <!--end::Form-->
          </div>
          <!--end::Menu 1-->
        </div>
        <!--end::Filter menu-->
      </div>
      <!--end::Actions-->
      <!--end::Page title-->
    </template>
    <!--begin: Audiences Table -->
    <div class="card card-flush">
      <div class="card-body p-9">
        <LoaderOverlay
          size="big"
          color="color2"
          v-if="loader"
        />

        <GenericTable
          :generic-data="localAudiences"
          @load-more="loadMore"
          :is-load-more-visible="isLoadMoreVisible"
          :loading="loader"
          :table-headers="['Nome', 'Tipo', 'Status']"
          v-if="localAudiences.length > 0"
        ></GenericTable>
      </div>
    </div>
    <!--end: Audiences Table -->
  </MainLayout>
</template>
<script>
import MainLayout from '@/components/layouts/MainLayout.vue'
import AudiencesService from '@/modules/audiences/services/audiences-service'
import GenericTable from '@/components/common/Table/GenericTable.vue'
import LoaderOverlay from '@/components/common/Loader/LoaderOverlay.vue'

export default {
  components: {
    LoaderOverlay,
    GenericTable,
    MainLayout
  },
  data () {
    return {
      filters: {
        search: '',
        is_active: true
      },
      isLoadMoreVisible: true,
      isFiltersOpen: false,
      localAudiences: [],
      loader: false,
      page: 1,
      total: 0,
      types: ['EMAIL_AUDIENCE', 'SITE_LIST']
    }
  },
  created () {
    this.getAudiences()
  },
  methods: {
    /**
     * Apply filters and search
     */
    applyFilters () {
      this.isFiltersOpen = false
      this.page = 1
      this.localAudiences = []
      this.getAudiences()
    },
    /**
     * Get targetings
     */
    async getAudiences () {
      this.loader = true
      try {
        const audiences = await AudiencesService.getAudiences({
          isActive: Number(this.filters.is_active),
          search: this.filters.search,
          page: this.page
        })
        this.localAudiences = [...this.localAudiences, ...audiences.data]
        this.isLoadMoreVisible = audiences.meta.current_page !== audiences.meta.last_page
        this.total = audiences.meta.total
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getAudiences()
    },
    /**
     * Reset Data
     */
    reset () {
      this.isFiltersOpen = false
      this.filters.search = ''
      this.filters.is_active = true
      this.page = 1
      this.localAudiences = []
      this.getAudiences()
    }
  }
}
</script>
